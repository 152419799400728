import React from "react";
import { FaBootstrap, FaHtml5, FaPhp, FaReact } from "react-icons/fa";
import { SiFirebase, SiJavascript, SiMysql } from "react-icons/si";
import { BsArrowUpRight } from "react-icons/bs";
import "./Projects.css";
// import getImages from "../../services/getImages";
// import config from "../../config";
import OwlCarousel from 'react-owl-carousel';

import cursodonticMain from '../../assets/images/cursodontic/main.jpg';
import cursodonticMarketplace from '../../assets/images/cursodontic/marketplace.jpg';
import cursodonticMembership from '../../assets/images/cursodontic/membership.jpg';
import cursodonticMyCourses from '../../assets/images/cursodontic/my-courses.jpg';
import cursodonticMyProfile from '../../assets/images/cursodontic/my-profile.jpg';
import cursodonticNotifications from '../../assets/images/cursodontic/notifications.jpg';
import cursodonticCourseView from '../../assets/images/cursodontic/course-view.jpg';
import cursodonticDashboard from '../../assets/images/cursodontic/dashboard.jpg';
import cursodonticHome from '../../assets/images/cursodontic/home.jpg';

import promainmain from '../../assets/images/promain/main.jpg';
import promaincertificaciones from '../../assets/images/promain/certificaciones.jpg';
import promaincontacto from '../../assets/images/promain/contacto.jpg';
import promaindistribuidor from '../../assets/images/promain/distribuidor.jpg';
import promainhome from '../../assets/images/promain/home.jpg';
import promainindustrias from '../../assets/images/promain/industrias.jpg';
import promainproductos from '../../assets/images/promain/productos.jpg';

import divumblog from '../../assets/images/divum/blog.jpg';
import divumcontacto from '../../assets/images/divum/contacto.jpg';
import divumdescripcion from '../../assets/images/divum/descripcion.jpg';
import divumeditor from '../../assets/images/divum/editor.jpg';
import divumhome from '../../assets/images/divum/home.jpg';
import divummain from '../../assets/images/divum/main.jpg';
import divumnosotros from '../../assets/images/divum/nosotros.jpg';
import divumpost from '../../assets/images/divum/post.jpg';
import divumservicios from '../../assets/images/divum/servicios.jpg';

import dashboardmain from '../../assets/images/dashboard/main.jpg';
import dashboardtabla from '../../assets/images/dashboard/tabla.jpg';
import dashboardespecifico from '../../assets/images/dashboard/especifico.jpg';
import dashboardlogin from '../../assets/images/dashboard/login.jpg';
import dashboardreporte from '../../assets/images/dashboard/reporte.jpg';

import opiniocomite from '../../assets/images/opinio/comite.jpg';
import opiniocontacto from '../../assets/images/opinio/contacto.jpg';
import opiniocriterios from '../../assets/images/opinio/criterios.jpg';
import opiniomain from '../../assets/images/opinio/main.jpg';
import opiniodetalle from '../../assets/images/opinio/detalle.jpg';
import opiniohome from '../../assets/images/opinio/home.jpg';
import opiniopublicaciones from '../../assets/images/opinio/publicaciones.jpg';

import divumBelleza from '../../assets/images/divum-belleza/home.jpg';
import divumBellezaNosotros from '../../assets/images/divum-belleza/home-nosotros.jpg';
import divumBellezaContacto from '../../assets/images/divum-belleza/contacto.jpg';
import divumBellezaEquipo from '../../assets/images/divum-belleza/equipo.jpg';
import divumBellezaRejuvenecimientoVaginal from '../../assets/images/divum-belleza/rejuvenecimiento-vaginal.jpg';
import divumBellezaServicios from '../../assets/images/divum-belleza/servicios.jpg';

import EJAHome from '../../assets/images/enlace-juridico-academico/home.jpg';
import EJAEntrevistaHome from '../../assets/images/enlace-juridico-academico/entrevista-home.jpg';
import EJAEntrevistaSolo from '../../assets/images/enlace-juridico-academico/entrevista-solo.jpg';
import EJAPodcastHome from '../../assets/images/enlace-juridico-academico/podcast-home.jpg';
import EJAPodcastSolo from '../../assets/images/enlace-juridico-academico/podcast-solo.jpg';
import EJAProyectosHome from '../../assets/images/enlace-juridico-academico/proyectos-home.jpg';
import EJAProyectosSolo from '../../assets/images/enlace-juridico-academico/proyectos-solo.jpg';
import EJAVideoHome from '../../assets/images/enlace-juridico-academico/video-home.jpg';
import EJAVideoSolo from '../../assets/images/enlace-juridico-academico/video-solo.jpg';


const Projects = () => {

  const cursodonticImages = [
    {"id_imageProject":"1","image_url":cursodonticMain,"alt_tag":"Home Cursodontic","status":"1","id_project":"1"},
    {"id_imageProject":"2","image_url":cursodonticMarketplace,"alt_tag":"Marketplace Cursodontic","status":"1","id_project":"1"},
    {"id_imageProject":"3","image_url":cursodonticMembership,"alt_tag":"Membership Cursodontic","status":"1","id_project":"1"},
    {"id_imageProject":"5","image_url":cursodonticMyCourses,"alt_tag":"Mis cursos Cursodontic","status":"1","id_project":"1"},
    {"id_imageProject":"6","image_url":cursodonticMyProfile,"alt_tag":"Mi perfil Cursodontic","status":"1","id_project":"1"},
    {"id_imageProject":"7","image_url":cursodonticNotifications,"alt_tag":"Notificaciones Cursodontic","status":"1","id_project":"1"},
    {"id_imageProject":"8","image_url":cursodonticCourseView,"alt_tag":"Curso Cursodontic","status":"1","id_project":"1"},
    {"id_imageProject":"9","image_url":cursodonticDashboard,"alt_tag":"Dashboard Cursodontic","status":"1","id_project":"1"},
    {"id_imageProject":"10","image_url":cursodonticHome,"alt_tag":"Home Cursodontic","status":"1","id_project":"1"},
  ]

  // const promainImages = [
  //   {"id_imageProject":"11","image_url": promainmain,"alt_tag":"INICIO - PROMAIN","status":"1","id_project":"2"},
  //   {"id_imageProject":"12","image_url": promaincertificaciones,"alt_tag":"CERTIFICACIONES - PROMAIN","status":"1","id_project":"2"},
  //   {"id_imageProject":"13","image_url": promaincontacto,"alt_tag":"CONTACTO - PROMAIN","status":"1","id_project":"2"},
  //   {"id_imageProject":"14","image_url": promaindistribuidor,"alt_tag":"DISTRIBUIDOR PROMAIN","status":"1","id_project":"2"},
  //   {"id_imageProject":"15","image_url": promainhome,"alt_tag":"HOME - PROMAIN","status":"1","id_project":"2"},
  //   {"id_imageProject":"16","image_url": promainindustrias,"alt_tag":"INDUSTRIAS - PROMAIN","status":"1","id_project":"2"},
  //   {"id_imageProject":"17","image_url": promainproductos,"alt_tag":"PRODUCTOS - PROMAIN","status":"1","id_project":"2"},
  // ]

  const dashboardEja = [
    {"id_imageProject":"18","image_url": dashboardmain,"alt_tag":"DASHBOARD EJA","status":"1","id_project":"4"},
    {"id_imageProject":"19","image_url": dashboardtabla,"alt_tag":"MÉTRICAS  EJA","status":"1","id_project":"4"},
    {"id_imageProject":"20","image_url": dashboardespecifico,"alt_tag":"MÉTRICAS EJA","status":"1","id_project":"4"},
    {"id_imageProject":"21","image_url": dashboardlogin,"alt_tag":"LOGIN EJA","status":"1","id_project":"4"},
    {"id_imageProject":"22","image_url": dashboardreporte,"alt_tag":"REPORTE EJA","status":"1","id_project":"4"},
  ]

  const divumImages = [
    {"id_imageProject":"23","image_url": divumblog,"alt_tag":"BLOG DIVUM","status":"1","id_project":"3"},
    {"id_imageProject":"24","image_url": divumcontacto,"alt_tag":"CONTACTO DIVUM","status":"1","id_project":"3"},
    {"id_imageProject":"25","image_url": divumdescripcion,"alt_tag":"DESCRIPCIÓN DE SERVICIO DIVUM","status":"1","id_project":"3"},
    {"id_imageProject":"26","image_url": divumeditor,"alt_tag":"EDITOR DEL BLOG DIVUM","status":"1","id_project":"3"},
    {"id_imageProject":"27","image_url": divumhome,"alt_tag":"INICIO DIVUM","status":"1","id_project":"3"},
    {"id_imageProject":"28","image_url": divummain,"alt_tag":"DIVUM","status":"1","id_project":"3"},
    {"id_imageProject":"29","image_url": divumnosotros,"alt_tag":"NOSOTROS DIVUM","status":"1","id_project":"3"},
    {"id_imageProject":"30","image_url": divumpost,"alt_tag":"BLOG POST DIVUM","status":"1","id_project":"3"},
    {"id_imageProject":"31","image_url": divumservicios,"alt_tag":"SERVICIOS DIVUM","status":"1","id_project":"3"},
  ]

  const opinio = [
    {"id_imageProject":"32","image_url": opiniocomite,"alt_tag":"COMITÉ OPINIO IURIS","status":"1","id_project":"5"},
    {"id_imageProject":"33","image_url": opiniocontacto,"alt_tag":"CONTACTO OPINIO IURIS","status":"1","id_project":"5"},
    {"id_imageProject":"34","image_url": opiniocriterios,"alt_tag":"CRITERIOS OPINIO IURIS","status":"1","id_project":"5"},
    {"id_imageProject":"35","image_url": opiniomain,"alt_tag":"OPINIO IURIS","status":"1","id_project":"5"},
    {"id_imageProject":"36","image_url": opiniodetalle,"alt_tag":"DETALLE OPINIO IURIS","status":"1","id_project":"5"},
    {"id_imageProject":"37","image_url": opiniohome,"alt_tag":"INICIO OPINIO IURIS","status":"1","id_project":"5"},
    {"id_imageProject":"38","image_url": opiniopublicaciones,"alt_tag":"PUBLICACIONES OPINIO IURIS","status":"1","id_project":"5"}
  ]

  const divumBellezaImages = [
    {"id_imageProject":"39","image_url": divumBelleza,"alt_tag":"HOME DIVUM","status":"1","id_project":"6"},
    {"id_imageProject":"40","image_url": divumBellezaNosotros,"alt_tag":"NOSOTROS DIVUM","status":"1","id_project":"6"},
    {"id_imageProject":"41","image_url": divumBellezaContacto,"alt_tag":"CONTACTO DIVUM","status":"1","id_project":"6"},
    {"id_imageProject":"42","image_url": divumBellezaEquipo,"alt_tag":"EQUIPO DIVUM","status":"1","id_project":"6"},
    {"id_imageProject":"43","image_url": divumBellezaRejuvenecimientoVaginal,"alt_tag":"REJUVENECIMIENTO VAGINAL DIVUM","status":"1","id_project":"6"},
    {"id_imageProject":"44","image_url": divumBellezaServicios,"alt_tag":"SERVICIOS DIVUM","status":"1","id_project":"6"}
  ]
  
  const enlaceJuridicoAcademico = [
    {"id_imageProject":"45","image_url": EJAHome,"alt_tag":"EJA HOME","status":"1","id_project":"7"},
    {"id_imageProject":"46","image_url": EJAEntrevistaHome,"alt_tag":"EJA ENTREVISTA HOME","status":"1","id_project":"7"},
    {"id_imageProject":"47","image_url": EJAEntrevistaSolo,"alt_tag":"EJA ENTREVISTA SOLO","status":"1","id_project":"7"},
    {"id_imageProject":"48","image_url": EJAPodcastHome,"alt_tag":"EJA PODCAST HOME","status":"1","id_project":"7"},
    {"id_imageProject":"49","image_url": EJAPodcastSolo,"alt_tag":"EJA PODCAST SOLO","status":"1","id_project":"7"},
    {"id_imageProject":"50","image_url": EJAProyectosHome,"alt_tag":"EJA PROYECTOS HOME","status":"1","id_project":"7"},
    {"id_imageProject":"51","image_url": EJAProyectosSolo,"alt_tag":"EJA PROYECTOS SOLO","status":"1","id_project":"7"},
    {"id_imageProject":"52","image_url": EJAVideoHome,"alt_tag":"EJA VIDEO HOME","status":"1","id_project":"7"},
    {"id_imageProject":"53","image_url": EJAVideoSolo,"alt_tag":"EJA VIDEO SOLO","status":"1","id_project":"7"},
  ]

  const state = {
    responsive:{
        0: {
            items: 1,
            center: true
        },
        576: {
            items: 1,
            center: true
        },
        768: {
            items: 1,
            center: true
        },
        992: {
            items: 2,
            center: true
        },
        1200: {
            items: 2,
            center: true
        },
    },
  }

  return (
    <div className="projects container-fluid">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 d-flex align-items-center" data-aos="fade-up" data-aos-duration="800">
            <h2 className="fs-1 mb-3 blue-color">Projects</h2>
            <span className="skills__subtitle-background">PROJECTS</span>
          </div>
          <div className="my-5">
            <div className="row">
              <>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects__description">
                  <div data-aos="fade-right" data-aos-duration="1000">
                    <h3 className="fw-bold fs-1 mb-3">CURSODONTIC</h3>
                  </div>
                  <p>
                    The website is an advanced e-learning platform that offers a comprehensive range of dental courses. With presence across Latin America and other Spanish-speaking regions around the world, the platform is tailored to meet the diverse learning needs of dental professionals.
                  </p>
                  <div className="d-flex">
                    <a
                      href="https://cursodontic.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        Visit <BsArrowUpRight />
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects__technologies" >
                  <h5 className="fw-bold mb-0">Technologies</h5>
                  <div className="projects__techIcons">
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1600">
                      <FaReact className="black-color" />
                      <span className="tooltiptext mt-1">ReactJS</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1400">
                      <FaBootstrap className="black-color" />
                      <span className="tooltiptext mt-1">Bootstrap</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1200">
                      <FaPhp className="black-color" />
                      <span className="tooltiptext mt-1">PHP</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1000">
                      <SiMysql className="black-color" />
                      <span className="tooltiptext mt-1">MySQL</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="800">
                      <SiFirebase className="black-color" />
                      <span className="tooltiptext mt-1">Firebase</span>
                    </div>
                  </div>
                </div>
                <div className="pb-5">
                  <OwlCarousel
                    className="owl-theme industry__owl"
                    items="2"
                    autoplay={true}
                    loop
                    animateIn={true}
                    responsive={state.responsive}
                    center={true}
                    animateOut={'fadeOut'}
                    autoplayHoverPause={true}
                    margin={15}
                    // data-aos="fade" data-aos-duration="2000"
                  >
                    {cursodonticImages?.map(image => (
                      <div key={image.alt_tag+image.id_imageProject} className="pt-4">
                        <img
                          src={image.image_url}
                          className="d-block w-100 shadow"
                          alt={image.alt_tag}
                        />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </>
              
              {/* <>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects__description">
                  <div data-aos="fade-right" data-aos-duration="1000">
                    <h3 className="fw-bold fs-1 mb-3">PROMAIN INDUSTRIAL</h3>
                  </div>
                  <p>
                    For this project I designed and developed the website's UI/UX, with the main objective of generating leads and attracting distributors. The website effectively showcases the products and services, providing a comprehensive overview.
                  </p>
                  <div className="d-flex">
                    <a
                      href="http://promainindustrial.com.mx/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        Visit <BsArrowUpRight />
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects__technologies">
                  <h5 className="fw-bold mb-0">Technologies</h5>
                  <div className="projects__techIcons">
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1400">
                      <FaReact className="black-color" />
                      <span className="tooltiptext mt-1">ReactJS</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1200">
                      <FaBootstrap className="black-color" />
                      <span className="tooltiptext mt-1">Bootstrap</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1000">
                      <FaPhp className="black-color" />
                      <span className="tooltiptext mt-1">PHP</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="800">
                      <SiMysql className="black-color" />
                      <span className="tooltiptext mt-1">MySQL</span>
                    </div>
                  </div>
                </div>
                <div className="pb-5">
                  <OwlCarousel
                    className="owl-theme industry__owl"
                    items="2"
                    autoplay={true}
                    loop
                    animateIn={true}
                    responsive={state.responsive}
                    center={true}
                    animateOut={'fadeOut'}
                    autoplayHoverPause={true}
                    margin={15}
                  >
                    {promainImages?.map(image => (
                      <div key={image.alt_tag+image.id_imageProject} className="pt-4">
                        <img
                          src={image.image_url}
                          className="d-block w-100 shadow"
                          alt={image.alt_tag}
                        />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </> */}

              <>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects__description">
                  <div data-aos="fade-right" data-aos-duration="1000">
                    <h3 className="fw-bold fs-1 mb-3">DIVUM BELLEZA</h3>
                  </div>
                  <p>
                    For this project I completely developed a website for Divum Belleza, a company that offers different services. I implemented the UI/UX, the frontend with ReactJS and the backend with PHP and MySQL. My participation included the design of the entire interface, the programming of the frontend and backend, and the integration of functionalities such as a content management system.
                  </p>
                  <div className="d-flex">
                    <a
                      href="http://belleza.divumclinic.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        Visit <BsArrowUpRight />
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects__technologies">
                  <h5 className="fw-bold mb-0">Technologies</h5>
                  <div className="projects__techIcons">
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1400">
                      <FaReact className="black-color" />
                      <span className="tooltiptext mt-1">ReactJS</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1200">
                      <FaBootstrap className="black-color" />
                      <span className="tooltiptext mt-1">Bootstrap</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1000">
                      <FaPhp className="black-color" />
                      <span className="tooltiptext mt-1">PHP</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="800">
                      <SiMysql className="black-color" />
                      <span className="tooltiptext mt-1">MySQL</span>
                    </div>
                  </div>
                </div>
                <div className="pb-5">
                  <OwlCarousel
                    className="owl-theme industry__owl"
                    items="2"
                    autoplay={true}
                    loop
                    animateIn={true}
                    responsive={state.responsive}
                    center={true}
                    animateOut={'fadeOut'}
                    autoplayHoverPause={true}
                    margin={15}
                  >
                    {divumBellezaImages?.map(image => (
                      <div key={image.alt_tag+image.id_imageProject} className="pt-4">
                        <img
                          src={image.image_url}
                          className="d-block w-100 shadow"
                          alt={image.alt_tag}
                        />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </>

              <>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects__description">
                  <div data-aos="fade-right" data-aos-duration="1000">
                    <h3 className="fw-bold fs-1 mb-3">DIVUM CLINIC</h3>
                  </div>
                  <p>
                    As part of the website development project, I have successfully integrated a blog along with a versatile module for post uploads. With this module, users can effortlessly customize their posts by incorporating images, and have the flexibility to edit or delete their posts.
                  </p>
                  <div className="d-flex">
                    <a
                      href="https://divumclinic.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        Visit <BsArrowUpRight />
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects__technologies">
                  <h5 className="fw-bold mb-0">Technologies</h5>
                  <div className="projects__techIcons">
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1400">
                      <FaReact className="black-color" />
                      <span className="tooltiptext mt-1">ReactJS</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1200">
                      <FaBootstrap className="black-color" />
                      <span className="tooltiptext mt-1">Bootstrap</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1000">
                      <FaPhp className="black-color" />
                      <span className="tooltiptext mt-1">PHP</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="800">
                      <SiMysql className="black-color" />
                      <span className="tooltiptext mt-1">MySQL</span>
                    </div>
                  </div>
                </div>
                <div className="pb-5">
                  <OwlCarousel
                    className="owl-theme industry__owl"
                    items="2"
                    autoplay={true}
                    loop
                    animateIn={true}
                    responsive={state.responsive}
                    center={true}
                    animateOut={'fadeOut'}
                    autoplayHoverPause={true}
                    margin={15}
                  >
                    {divumImages?.map(image => (
                      <div key={image.alt_tag+image.id_imageProject} className="pt-4">
                        <img
                          src={image.image_url}
                          className="d-block w-100 shadow"
                          alt={image.alt_tag}
                        />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </>
              
              <>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects__description">
                  <div data-aos="fade-right" data-aos-duration="1000">
                    <h3 className="fw-bold fs-1 mb-3">DASHBOARD EJA</h3>
                  </div>
                  <p className="fw-light fs-6">By: Enlace Jurídico Académico</p>
                  <p>
                    The main function of this dashboard is to provide accurate and important data on website usage metrics. By handling the dashboard, administrators can effectively track the number of visitors to each website they own.
                  </p>
                  <div className="d-flex">
                    <a
                      href="http://dashboard.enlacejuridicoacademico.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        Visit <BsArrowUpRight />
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects__technologies">
                  <h5 className="fw-bold mb-0">Technologies</h5>
                  <div className="projects__techIcons">
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1400">
                      <FaReact className="black-color" />
                      <span className="tooltiptext mt-1">ReactJS</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1200">
                      <FaBootstrap className="black-color" />
                      <span className="tooltiptext mt-1">Bootstrap</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1000">
                      <FaPhp className="black-color" />
                      <span className="tooltiptext mt-1">PHP</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="800">
                      <SiMysql className="black-color" />
                      <span className="tooltiptext mt-1">MySQL</span>
                    </div>
                  </div>
                </div>
                <div className="pb-5">
                  <OwlCarousel
                    className="owl-theme industry__owl"
                    items="2"
                    autoplay={true}
                    loop
                    animateIn={true}
                    responsive={state.responsive}
                    center={true}
                    animateOut={'fadeOut'}
                    autoplayHoverPause={true}
                    margin={15}
                  >
                    {dashboardEja?.map(image => (
                      <div key={image.alt_tag+image.id_imageProject} className="pt-4">
                        <img
                          src={image.image_url}
                          className="d-block w-100 shadow"
                          alt={image.alt_tag}
                        />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </>

              <>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects__description">
                  <div data-aos="fade-right" data-aos-duration="1000">
                    <h3 className="fw-bold fs-1 mb-3">REVISTA OPINIO IURIS</h3>
                  </div>
                  <p className="fw-light fs-6">By: Enlace Jurídico Académico</p>
                  <p>
                    For this project I designed and developed the website's UI/UX, with the objective of share academic articles. 
                  </p>
                  <div className="d-flex">
                    <a
                      href="http://revistaopinioiuris.enlacejuridicoacademico.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        Visit <BsArrowUpRight />
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects__technologies">
                  <h5 className="fw-bold mb-0">Technologies</h5>
                  <div className="projects__techIcons">
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1600">
                      <FaHtml5 className="black-color" />
                      <span className="tooltiptext mt-1">HTML5</span>
                    </div>
                    {/* <div className="tooltop" data-aos="fade-up" data-aos-duration="800">
                      <FaCss3Alt className="black-color" />
                      <span className="tooltiptext mt-1">CSS</span>
                    </div> */}
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1400">
                      <SiJavascript className="black-color" />
                      <span className="tooltiptext mt-1">JavaScript</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1200">
                      <FaBootstrap className="black-color" />
                      <span className="tooltiptext mt-1">Bootstrap</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1000">
                      <FaPhp className="black-color" />
                      <span className="tooltiptext mt-1">PHP</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="800">
                      <SiMysql className="black-color" />
                      <span className="tooltiptext mt-1">MySQL</span>
                    </div>
                  </div>
                </div>
                <div className="pb-5">
                  <OwlCarousel
                    className="owl-theme industry__owl"
                    items="2"
                    autoplay={true}
                    loop
                    animateIn={true}
                    responsive={state.responsive}
                    center={true}
                    animateOut={'fadeOut'}
                    autoplayHoverPause={true}
                    margin={15}
                  >
                    {opinio?.map(image => (
                      <div key={image.alt_tag+image.id_imageProject} className="pt-4">
                        <img
                          src={image.image_url}
                          className="d-block w-100 shadow"
                          alt={image.alt_tag}
                        />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </>

              <>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects__description">
                  <div data-aos="fade-right" data-aos-duration="1000">
                    <h3 className="fw-bold fs-1 mb-3">Enlace Jurídico Académico</h3>
                  </div>
                  <p className="fw-light fs-6">By: Enlace Jurídico Académico</p>
                  <p>
                    For this project I performed an optimization on this website, which had poor code structure and architecture resulting in long loading times. I implemented a new architecture separating the modules into different pages, which improved the loading time of the main page and each individual page. It should be noted that I was not involved in the original development of the website or its design. 
                  </p>
                  <div className="d-flex">
                    <a
                      href="http://enlacejuridicoacademico.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        Visit <BsArrowUpRight />
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects__technologies">
                  <h5 className="fw-bold mb-0">Technologies</h5>
                  <div className="projects__techIcons">
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1600">
                      <FaHtml5 className="black-color" />
                      <span className="tooltiptext mt-1">HTML5</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1400">
                      <SiJavascript className="black-color" />
                      <span className="tooltiptext mt-1">JavaScript</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1200">
                      <FaBootstrap className="black-color" />
                      <span className="tooltiptext mt-1">Bootstrap</span>
                    </div>
                  </div>
                </div>
                <div className="pb-5">
                  <OwlCarousel
                    className="owl-theme industry__owl"
                    items="2"
                    autoplay={true}
                    loop
                    animateIn={true}
                    responsive={state.responsive}
                    center={true}
                    animateOut={'fadeOut'}
                    autoplayHoverPause={true}
                    margin={15}
                  >
                    {enlaceJuridicoAcademico?.map(image => (
                      <div key={image.alt_tag+image.id_imageProject} className="pt-4">
                        <img
                          src={image.image_url}
                          className="d-block w-100 shadow"
                          alt={image.alt_tag}
                        />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
